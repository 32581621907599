'use client';
import { useRouter } from 'next/navigation';
import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import toast from "react-hot-toast";
import { toastStyles } from "@/conf/constants";
import { signIn } from 'next-auth/react';
import Image from 'next/image';
const Login = () => {
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [clickedButton, setClickedButton] = useState(null);
  const [showValidation, setShowValidation] = React.useState<boolean>(false);
  const [passwordError, setPasswordError] = React.useState({
    hasUppercase: false,
    hasLowercase: false,
    hasSpecialChar: false,
    hasNumber: false,
    isLongEnough: false,
  });
  const router = useRouter();
  const backgroundStyle = {
    backgroundImage: "url('/background.svg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };
  const handleForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoggingIn) return; // Prevent multiple submissions
    setIsLoggingIn(true); // Set login status to true
    const result = await signIn('credentials', {
      redirect: false,
      identifier: email,
      password
    });
    if (result?.error) {
      setIsLoggingIn(false); // Reset login status on error
      if (result.error === 'CredentialsSignin') {
        toast.error('Incorrect username or password');
      } else {
        toast.error(result.error);
      }
    } else {
      toast.success('Login Successful!');
      router.push("/dashboard");
    }
  };
  const validatePassword = (value: string) => {
    setPasswordError({
      hasUppercase: /[A-Z]/.test(value),
      hasLowercase: /[a-z]/.test(value),
      hasSpecialChar: /[@$!%*?&]/.test(value),
      hasNumber: /\d/.test(value),
      isLongEnough: value.length >= 6,
    });
  };
  return (
    <div>
      {/* for desktop  */}
      <div className="hidden sm:flex justify-center items-center w-full h-screen">
        <div style={backgroundStyle} className="w-1/2 h-full hidden flex-col justify-between items-start p-10 sm:flex">
          <div className="w-1/4 flex justify-start">
            <Image
              src="/botafy-logo.svg"
              alt="Botafy"
              width={160}
              height={160}
              layout="responsive"
              className="rounded-full"
            />
          </div>
          <div className="w-full max-w-sm mx-auto mt-5">
            <Image
              src="/bot-image.svg"
              alt="Bot Image"
              width={300}
              height={300}
              layout="responsive"
            />
          </div>
          <div>
            <div className='text-white text-[20px] '>Glad to see you back!</div>
            <div className='text-white text-[20px] '> Login to continue using botafy </div>
          </div>
        </div>
        <div className="md:w-1/2 w-full h-full flex flex-col justify-center items-center bg-white text-gray-800 p-10">
          <div className="mx-auto my-24 max-w-md w-full">
            <form onSubmit={handleForm} className="space-y-4">
              <div className="justify-center mb-6 flex md:hidden">
                <Image src={'/botafy_logo_updated.svg'} alt="Botafy" width={150} height={50} priority={true} />
              </div>
              <h2 className="text-2xl  text-center mb-4">Log in</h2>
              <input className="w-full px-4 py-3 border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded-md bg-none" id="email" name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())} placeholder="Enter your email" required autoComplete='off' />
              <div className="relative">
                <input className="w-full px-4 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500" id="password" name="password" type={showPassword ? "text" : "password"} onChange={(e) => setPassword(e.target.value)} placeholder="Enter your password" required />
                <div className='cursor-pointer absolute right-4 top-4' onClick={() => setShowPassword(prev => !prev)}>
                  <Image src={!showPassword ? '/openeye.svg' : '/closeeye.svg'} alt="show/hide password icon" width={20} height={20} />
                </div>
              </div>
              <div className="text-sm text-[#1570EF] items-center justify-end flex"><div className='inline-flex cursor-pointer focus:outline-none focus:ring-2 focus:ring-indigo-500' onClick={() => router.push('/forgot-password')}>Forgot your Password?</div></div>
              <button type="submit" className="w-full py-3 text-lg rounded-md bg-blue-600 text-white border border-blue-500 hover:bg-white hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">Log in</button>
              <p className="text-sm text-center text-[#98A2B3]">Don't have an account? <button onClick={() => router.push('/signup')} className="text-[#1570EF] cursor-pointer focus:outline-none focus:ring-2 focus:ring-indigo-500">Sign Up</button></p>
              <h3 className=" text-center p-1 hidden">Login using</h3>
              <div className="hidden justify-between">
                <div onClick={() => signIn('google')} className="bg-[#ECF0F5] rounded-lg px-6 py-2 md:px-10 md:py-3 hover:bg-[#DDDFDD] cursor-not-allowed ">
                  <Image src="/google.svg" alt="google_logo" width={26} height={26} className="flex-none" />
                </div>
                <div className="bg-[#ECF0F5] rounded-lg px-6 py-2 md:px-10 md:py-3 hover:bg-[#DDDFDD] cursor-not-allowed ">
                  <Image src="/apple.svg" alt="apple_logo" width={23} height={23} className="flex-none" />
                </div>
                <div className="bg-[#ECF0F5] rounded-lg px-6 py-2 md:px-10 md:py-3 hover:bg-[#DDDFDD] cursor-not-allowed ">
                  <Image src="/microsoft.svg" alt="microsoft_logo" width={28} height={28} className="flex-none" />
                </div>
              </div>
              <p className="text-center text-sm text-[#98A2B3]">
                <span className="cursor-pointer" onClick={() => { router.push('/terms-and-conditions') }} >Terms & Conditions</span> | <span className="cursor-pointer " onClick={() => { router.push('/privacy-policy') }}>Privacy Policy</span>
              </p>
            </form>
          </div>
        </div>

      </div>
      {/* for mobile  */}
      <div
        className="flex flex-col items-center justify-start sm:hidden space-y-2 "
      >
        <div className="flex flex-col gap-3 w-full">
          <div
            style={backgroundStyle}
            className="w-full h-[53vh] relative flex flex-col items-center justify-center"
          >
            {/* Logo Section */}
            <div className="absolute top-4 left-4">
              <Image
                src="/botafy-logo.svg"
                alt="Botafy"
                width={123}
                height={28}
                layout="intrinsic"
              />
            </div>
            {/* Centered Bot Image */}
            <div className="flex justify-center items-center">
              <Image
                src="/bot-image.svg"
                alt="Bot Image"
                layout="intrinsic"
                width={230} // Default width for mobile
                height={246} // Default height for mobile
                className="sm:w-[200px] sm:h-[200px] md:w-[240px] md:h-[240px]" // Adjusted sizes for larger screens
              />
            </div>
            {/* Text Section */}
            <div className="text-white text-xl sm:text-2xl md:text-3xl font-semibold font-['DM Sans'] leading-tight  absolute bottom-4">
              Glad to see you back!
              <br />
              Login to continue using Botafy
            </div>
          </div>
          <form onSubmit={handleForm} className="space-y-3 flex flex-col justify-center items-center ">
            <input
              className="w-full max-w-[360px]  px-3 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 text-lg"
              id="email1"
              name="email"
              value={email}
              type="email"
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              placeholder="Enter your email"
              required
            />
            <div className="relative w-full max-w-[360px]">
              <input
                className="w-full px-3 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 text-lg"
                id="password1"
                name="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onFocus={() => setShowValidation(true)}
                onBlur={() => setShowValidation(false)}
                onChange={(e) => {
                  setPassword(e.target.value);
                  validatePassword(e.target.value);
                }}
                placeholder="Enter your password"
                required
              />
              <div className="cursor-pointer absolute right-4 top-3" onClick={() => setShowPassword((prev) => !prev)}>
                <Image
                  src={!showPassword ? '/openeye.svg' : '/closeeye.svg'}
                  alt="show/hide password icon"
                  width={18}
                  height={18}
                />
              </div>
            </div>
            <button
              onClick={() => {
                setClickedButton('login');
                router.push('/login');
              }}
              className={`w-full max-w-[360px] h-[45px] bg-[#2874eb] rounded-[30px] flex justify-center items-center mx-auto 
              ${clickedButton === 'login' ? 'bg-[#1a5bd3]' : 'hover:bg-[#1a5bd3]'} 
              active:scale-95 transition-all duration-200`}
            >
              <div className="text-center text-white text-sm font-semibold font-['DM Sans']">
                Login
              </div>
            </button>
          </form>
          <div className="flex items-center justify-center w-full h-3">
            <div className="text-sm text-blue-600 cursor-pointer" onClick={() => router.push('/forgot-password')}>
              Forgot your Password?
            </div>
          </div>
          <div className="flex items-center justify-center gap-2 w-full h-3 relative">
            <div className="text-[#98a2b3] text-xs font-normal font-['DM Sans'] leading-3">
              New to Botafy?
            </div>
            <div className="text-[#1570ef] text-xs font-normal font-['DM Sans'] capitalize leading-3 cursor-pointer" onClick={() => {
              setClickedButton('/signup');
              router.push('/signup');
            }}>
              Sign Up
            </div>
          </div>
          <div className="hidden text-[#101828] text-base sm:text-lg md:text-xl font-semibold font-['DM Sans'] leading-none w-full text-center m-2">
            OR
          </div>
          <div className="hidden justify-center gap-4 w-full">
            <div onClick={() => signIn('google')} className="bg-[#ECF0F5] rounded-lg px-6 py-2 md:px-10 md:py-3 hover:bg-[#DDDFDD] cursor-not-allowed ">
              <Image src="/google.svg" alt="google_logo" width={26} height={26} className="flex-none" />
            </div>
            <div className="bg-[#ECF0F5] rounded-lg px-6 py-2 md:px-10 md:py-3 hover:bg-[#DDDFDD] cursor-not-allowed ">
              <Image src="/apple.svg" alt="apple_logo" width={23} height={23} className="flex-none" />
            </div>
            <div className="bg-[#ECF0F5] rounded-lg px-6 py-2 md:px-10 md:py-3 hover:bg-[#DDDFDD] cursor-not-allowed ">
              <Image src="/microsoft.svg" alt="microsoft_logo" width={28} height={28} className="flex-none" />
            </div>
          </div>
          <p className="text-center text-sm text-[#98A2B3]">
            <span className="cursor-pointer " onClick={() => { router.push('/terms-and-conditions') }}>Terms & Conditions</span> | <span className="cursor-pointer" onClick={() => { router.push('/privacy-policy') }}>Privacy Policy</span>
          </p>
        </div>
      </div>
    </div>
  );
}
export default Login;
